html, body {
  height: 100%; 
  margin: 0; 
  padding: 0;
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  flex-grow: 1;
}



body {
  background-color: #0b0b1a;
  color: white;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}


.btn-close {
  filter: invert(1);
}

.btn-close:focus, 
.btn-close:hover {
  filter: invert(1) brightness(0.8);
}

.custom-toggle .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.custom-offcanvas {
  background-color: #0b0b1a !important;
  color: #fdfbf9;
  height: 100%;
}

.custom-offcanvas .offcanvas-header {
  background-color: inherit;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.custom-offcanvas .offcanvas-body {
  background-color: inherit;
  color: inherit;
  margin-bottom: 20px;
}

.navbar-bg {
  background-color: #0b0b1a;
  font-size: 1rem;
  font-weight: bold;
}

.custom-dropdown .dropdown-toggle {
  background-color: #0b0b1a;
  color: #fdfbf9;
  font-weight: bold;
  border: none;
}

.custom-dropdown .dropdown-menu {
  background-color: #181842;
}

.custom-dropdown .dropdown-item {
  color: #fdfbf9;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #495057;
  color: #ffffff;
}

.custom-dropdown .dropdown-item:active {
  background-color: #6c757d;
  color: #ffffff;
}


.button-container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  gap: 20px; 
  margin-top: 20px; 
}


.text-box {
  padding: 20px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: center;
  color: white;
  background-color: #0b0b1a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.text-box h1 {
  font-size: 50px;
  line-height: 55px;
  background-color: #0b0b1a;
  color: white;
  margin: 0;
  height: 200px; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.text-box a {
  display: inline-block;
  border-radius: 60px;
  border: 1px solid #f3950d;
  color: #f3950d;
  padding: 8px 30px;
  width: 130px; 
  text-decoration: none;
  margin-bottom: 1000px;

}

.text-box .btn { 
  background-color: #0b0b1a;
  margin: 20px;
  font-weight: 600;
  display: inline-block;
  color: #f3950d;
  margin-bottom: 50px;
}


.myself-image {
  width: 300px;
  position: relative;
  border-radius: 50%;
  margin-top: 20px;
}

.Home, .Cyber, .Blockchain, .Education, .Contact{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  background-color: #0b0b1a; 
  padding: 20px;
  margin: 0;
  min-height: calc(100vh - 60px);
}

.sticky-button-container {
  display: flex;
  justify-content: center;
  gap: 45px;
  top: 0;
  position: relative;
  z-index: 1000;
  background-color: #0b0b1a; 
  padding: 10px 0;

}

.sticky-button-container .custom-button {
  background-color: rgb(20, 20, 128) !important; 
  color: #d4d4fd !important; 
  border-color: rgb(21, 21, 66) !important;
  width: 160px; 
  height: 40px;
  font-weight: 600;
  border-radius: 5px;
  margin-bottom: 10%;
}
.sticky-button-container .custom-button:hover {
  background-color: darkblue !important; 
  border-color: darkblue !important;
}
.centered-accordion {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
}

/* Custom Accordion Styling */
.custom-accordion {
  width: 100%;
  max-width: 700px;
  color: #0b0b1a;
  background-color: transparent;
  border-color: transparent;
  border-radius: 8px;
}

/* Accordion Item */
.custom-accordion-item {
  background-color: #0b0b1a;
  margin: 0;
  border-color: transparent;
}

/* Accordion Header */
.custom-accordion-header {
  padding: 0;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
}

/* Style the image inside the Accordion Header */
.accordion-header-img {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners at the top */
  border-color: transparent;
}

/* Accordion Body */
.custom-accordion-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0b0b1a;
  color: #ffffff;
  padding: 20px;
  border-top: none;
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
  border-color: transparent;
}

/* Accordion Body Title */
.accordion-body-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #f3950d; /* Accent color for the title */
}

/* Accordion Body Text */
.accordion-body-text {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.5;
}

.accordion-body-button { 
  background-color: #f3950d !important;
  margin: 20px;
  font-weight: 600;
  display: inline-block;
  color: #0b0b1a !important;
  border:#0b0b1a !important;
}


.auto-input {
  display: inline-block;
}

.graduate-image {
  width: 100%; 
  height: auto; 
}

.inno-image {
  width: 100%; 
  height: auto; 
}

.education-bachelor {
  margin-top: 200px;
}

.education-master {
  margin-bottom: 100px;
}

.tech-stack {
  margin-top: 150px;
  margin-bottom: 100px;
}
.tech-stack-table {
  border-collapse: collapse; 
  background-color: #0b0b1a;
}

.tech-stack-table td {
  padding: 10px 15px; 
  color: #f8f9fa !important; 
  background-color: #0b0b1a !important; 
  text-align: left
}

.tech-stack-table td img.tech-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px; 
}

.tech-stack-table th {
  text-align: center;
  padding: 15px;
  font-size: 1.5rem; 
  background-color: #0b0b1a !important; 
  color: #f8f9fa !important; 
}


.tech-stack-table,
.tech-stack-table th,
.tech-stack-table td {
  border: none;
}

.motion-tr,
.motion-td {
  transition: all 0.7s ease;
}


.oscp-image {
  width: 150px;
  height: auto;
  margin-top: 20px;
  align-items: center;
}

.text-box-cyber {
  padding: 20px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: start;
  color: white;
  background-color: #0b0b1a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.jypra-intern {
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 20px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: end;
  color: white;
  background-color: #0b0b1a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.jypra-logo {
  height: 100px;
  margin-top: 20px;
  margin-left: 30px;
}


.current-progress {
  padding: 20px;
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: start;
  color: white;
  background-color: #0b0b1a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.university-study-card {
  background-color: #0b0b1a !important;
  color: #f8f9fa;
  margin-top: 20px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: auto; 
  height: auto;
}

.university-study-card .text-container {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
}

.university-study-card .uni-image {
  width: 100%;
  height: auto;
  margin-left: 20px;
  object-fit: cover; 
}

.university-study-card h2 {
  color: #f8f9fa; 
  margin-bottom: 15px;
  justify-content: flex-start;
  font-size: rem;
}

.university-study-card p {
  color: #f8f9fa;
  justify-content: flex-start; 
}

.cyber-video {
  width: 640px; /* Default width for desktop */
  height: 360px; /* Default height for desktop */
  max-width: 100%; /* Ensure the video does not exceed the screen width */
  height: auto; /* Maintain the aspect ratio on smaller screens */
  display: block; /* Center the video if it's a block element */
  margin: 0 auto; /* Center the video horizontally */
}


.bc-video {
  width: 640px; /* Default width for desktop */
  height: 360px; /* Default height for desktop */
  max-width: 100%; /* Ensure the video does not exceed the screen width */
  height: auto; /* Maintain the aspect ratio on smaller screens */
  display: block; /* Center the video if it's a block element */
  margin: 0 auto; /* Center the video horizontally */
}

@media only screen and (max-width: 768px) {
  .bc-video {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Auto height to maintain aspect ratio */
  }

  .cyber-video {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Auto height to maintain aspect ratio */
  }

}

.custom-margin-col {
  margin-right: 25px; 
}

.inno-image {
  margin-bottom: 15px; 
}

.favorite-part {
  margin-top: 50px;
}

.capstone-project {
  margin-top: 100px;
  margin-bottom: 150px;
}

.carousel-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.custom-carousel .carousel-item img {
  max-height: 500px;
  object-fit: cover; 
  border-radius: 8px; 
}

.custom-carousel .carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 10px;
}
.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  width: 5%; 
  top: 50%; 
  transform: translateY(-50%); 
  border: none; 
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5); 
  background-size: 100%, 100%; 
  border-radius: 50%; 
}


.more-section {
  padding: 50px 20px;
  background-color: #0b0b1a; /* Dark background to match the theme */
  color: #f8f9fa; /* Light text color for contrast */
  text-align: center;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.section-description {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.image-item {
  flex: 1 1 calc(33.333% - 20px); /* Three images per row with some spacing */
  max-width: calc(33.333% - 20px);
}

.conference-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.conference-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.Hobbies {
  position: relative;
  overflow: hidden;
  min-height: 100vh; /* Ensure it covers the full height of the viewport */
}

.background-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the video is behind the text */
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
  z-index: -1; /* Ensure the video stays behind everything */
  position: absolute; /* Ensure the video covers the entire background */
  top: 0;
  left: 0; /* Ensure the video covers the entire container */
}

.background-image {
  display: none; /* Hide the image by default */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .background-video {
    display: none; /* Hide the video on small screens */
  }

  .background-image {
    display: block; /* Show the image on small screens */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.hb-text-box {
  position: relative;
  z-index: 1; /* Bring the text in front of the video */
  color: white;
  text-align: center;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background for readability */
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.hobbies-video {
  margin-top: 20px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.carousel-container {
  position: relative;
  z-index: 1; /* Ensure the carousel is above the video background */
  margin-top: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.hobbies-carousel-image {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

